import {
  OFFICE_DELETE_FAIL,
  OFFICE_MODIFY_FAIL,
  ADD_NEW_OFFICE,
  DELETE_OFFICE,
  LOADED_OFFICEES,
  LOAD_OFFICEES,
  LOAD_OFFICEES_FAIL,
  MODIFIED_OFFICE,
  RESET,
  OFFICE_ADD_FAIL,
} from '../actions/types';

const initialState = {
  loading: false,
  addresses: [],
  updated: false,
  created: false,
  error: false,
  deleted: false,
  updatedLoading: false,
  deletedLoading: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET:
      return {
        ...initialState,
      };
    case ADD_NEW_OFFICE:
      return {
        ...state,
        created: true,
        loading: false,
      };
    case LOADED_OFFICEES:
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      };
    case LOAD_OFFICEES:
      return {
        ...state,
        created: false,
        updated: false,
        deleted: false,
        error: false,
        errorAction: false,
        loading: true,
      };
    case MODIFIED_OFFICE:
      return {
        ...state,
        updated: true,
        loading: false,
      };
    case DELETE_OFFICE:
      return {
        ...state,
        deleted: true,
        loading: false,
      };
    case OFFICE_MODIFY_FAIL:
    case OFFICE_DELETE_FAIL:
    case LOAD_OFFICEES_FAIL:
    case OFFICE_ADD_FAIL:
      return {
        ...state,
        deleted: false,
        updated: false,
        created: false,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
