import {
  LOADED_FAIL_USERS,
  LOADED_USERS,
  LOADING_USERS,
  VERIFIED_FAIL_USER,
  VERIFIED_USER,
  VERIFYING_USER,
  LOADED_UL20_USERS,
  LOADED_FAIL_UL20_USERS,
  LINK_BILLING_ACCOUNT_ID_SUCCESS,
  LINK_BILLING_ACCOUNT_ID_FAILED,
  LINK_BILLING_ACCOUNT_ID_LOADING,
  USER_MAKE_PREPAID_FAILED,
  USER_MAKE_PREPAID_SUCCESS,
  OMITTING_USER,
  OMITTED_USERS,
  OMITTED_FAIL_USERS
} from '../actions/types';

const initialState = {
  loading: false,
  users: {
    contents: [],
    totalPages: 0,
    currentPage: 0,
  },
  ul20Users: {
    contents: [],
    totalPages: 0,
    currentPage: 0,
  },
  updating: false,
  verified: false,
  loadingOmit:false,
  omitted: false,
  errorOmit:false,
  errorMessageOmit:"",
  loadingVerify: false,
  errorVerified: false,
  errorVerifiedMessage: '',
  loadingLinkId: false,
  errorLinkId: false,
  successLinkId: false,
  errorLinkIdMessage: '',
  successPrepaid: false,
  errorPrepaid: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFYING_USER:
      return {
        ...state,
        loadingVerify: true,
        verified: false,
        errorVerified: false,
      };
    case VERIFIED_USER:
      return {
        ...state,
        loadingVerify: false,
        verified: true,
        errorVerified: false,
      };
    case VERIFIED_FAIL_USER:
      return {
        ...state,
        loadingVerify: false,
        verified: false,
        errorVerified: true,
        errorVerifiedMessage: action.payload,
      };
    case LOADED_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        updating: false,
        loadingVerify: false,
        loadingLinkId: false,
        errorLinkId: false,
        successLinkId: false,
        errorVerifiedMessage: '',
        verified: false,
        successPrepaid: false,
        errorPrepaid: false,
        errorOmit:false,
        omitted: false,
      };
    case LOADING_USERS:
      return {
        ...state,
        loading: true,
        successPrepaid: false,
        errorPrepaid: false,
        loadingVerify: false,
      };
    case LOADED_FAIL_USERS:
    case LOADED_FAIL_UL20_USERS:
      return {
        ...state,
        error: true,
        loading: false,
        verified: false,
        successPrepaid: false,
        errorPrepaid: false,
      };
    case LOADED_UL20_USERS:
      return {
        ...state,
        ul20Users: action.payload,
        loading: false,
        updating: false,
        loadingVerify: false,
        loadingLinkId: false,
        errorLinkId: false,
        successLinkId: false,
        verified: false,
        successPrepaid: false,
        errorPrepaid: false,
      };
    case LINK_BILLING_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        successLinkId: true,
        errorLinkId: false,
      };
    case LINK_BILLING_ACCOUNT_ID_FAILED:
      return {
        ...state,
        successLinkId: false,
        errorLinkId: true,
        errorLinkIdMessage: action.payload,
      };
    case LINK_BILLING_ACCOUNT_ID_LOADING:
      return {
        ...state,
        loadingLinkId: true,
        errorLinkId: false,
        successLinkId: false,
      };
    case USER_MAKE_PREPAID_SUCCESS:
      return {
        ...state,
        successPrepaid: true,
        errorPrepaid: false,
      };
    case USER_MAKE_PREPAID_FAILED:
      return {
        ...state,
        successPrepaid: false,
        errorPrepaid: true,
      };
    case OMITTING_USER:
      return {
        ...state,
        loadingOmit:true,
      };
    case OMITTED_USERS:
      return {
        ...state,
        loadingOmit:false,
        omitted: true,
      };
    case OMITTED_FAIL_USERS:
      return {
        ...state,
        loadingOmit:false,
        errorOmit:true,
        errorMessageOmit:action.payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
