import {
  SHIPMENT_GETQBCUSTOMER_FAILED,
  SHIPMENT_GETQBCUSTOMER_SUCCESS,
  SHIPMENT_GETQBCUSTOMER_LOADING,
  SHIPMENT_PRINT_SHIPPING_LABEL_LOADING,
  SHIPMENT_PRINT_SHIPPING_LABEL_SUCCESS,
  SHIPMENT_PRINT_SHIPPING_LABEL_FAILED,
  SHIPMENT_MAIl_SHIPPING_LABEL_LOADING,
  SHIPMENT_MAIl_SHIPPING_LABEL_SUCCESS,
  SHIPMENT_MAIl_SHIPPING_LABEL_FAILED,
  SHIPMENT_PRINT_RETURN_LABEL_LOADING,
  SHIPMENT_PRINT_RETURN_LABEL_SUCCESS,
  SHIPMENT_PRINT_RETURN_LABEL_FAILED,
  SHIPMENT_RESET,
  VALIDATE_POSTAL_CODE_SUCCESS,
  VALIDATE_POSTAL_CODE_FAILED,
  VALIDATE_POSTAL_CODE_LOADING,
} from '../actions/types';

export const initialState = {
  validatePostalCode: true,
  validatePostalCodeLoading: false,
  validatePostalCodeErrorMessage: '',
  mailLabelLoading: false,
  mailLabelSuccess: false,
  mailLabelErrorMessage: '',
  createLabelLoading: false,
  createLabelSuccess: false,
  createLabelErrorMessage: '',
  createLabelReturnLoading: false,
  createLabelReturnSuccess: false,
  createLabelReturnErrorMessage: '',
  labelData: {},
  qbcustomerLoading: false,
  qbcustomerSuccess: false,
  qbcustomerErroMessage: '',
  qbcustomerData: {
    billingId: null,
    fedexAccountNumber: '',
    recipientContact: '',
    recipientCountry: '',
    recipientEmailId: '',
    recipientPhoneNumber: '',
    recipientCompanyName: '',
    recipientAddress1: '',
    recipientAddress2: '',
    recipientAddress3: '',
    recipientPostalCode: '',
    recipientState: '',
    recipientCity: '',
    senderCompany: '',
    senderContact: '',
    senderPhoneNumber: '',
    senderCountry: '',
    senderEmailId: '',
    senderAddress1: '',
    senderAddress2: '',
    senderCity: '',
    senderState: '',
    senderZipCode: '',
    numberOfPackages: 1,
    weight: 0,
    fedexServiceType: 'FEDEX_2_DAY',
    fedexPackageDimensions: '12 x 9 x 4',
    fedexShipDate: '',
    billTransportationTo: '',
    referenceType: '',
    referenceId: '',
    fedexPackagingType: 'YOUR_PACKAGING',
    qbinvoiceId: null,
    fedexDeclaredValue: 0,
  },
};

const shipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHIPMENT_GETQBCUSTOMER_LOADING:
      return {
        ...state,
        qbcustomerLoading: true,
        qbcustomerSuccess: false,
        qbcustomerErroMessage: '',
      };
    case SHIPMENT_GETQBCUSTOMER_SUCCESS:
      return {
        ...state,
        qbcustomerLoading: false,
        qbcustomerSuccess: true,
        qbcustomerErroMessage: '',
        qbcustomerData: {
          ...state.qbcustomerData,
          ...action.payload,
        },
      };
    case SHIPMENT_GETQBCUSTOMER_FAILED:
      return {
        ...state,
        qbcustomerLoading: false,
        qbcustomerSuccess: false,
        qbcustomerErroMessage:
          action.payload || 'Unable to get qbcustomer please try again',
      };
    case SHIPMENT_PRINT_SHIPPING_LABEL_LOADING:
      return {
        ...state,
        createLabelLoading: true,
        createLabelSuccess: false,
        createLabelErrorMessage: '',
      };
    case SHIPMENT_PRINT_SHIPPING_LABEL_SUCCESS:
      return {
        ...state,
        createLabelLoading: false,
        createLabelSuccess: true,
        labelData: action.payload,
      };
    case SHIPMENT_PRINT_SHIPPING_LABEL_FAILED:
      return {
        ...state,
        createLabelLoading: false,
        createLabelSuccess: false,
        createLabelErrorMessage: action.payload,
      };
    case SHIPMENT_PRINT_RETURN_LABEL_LOADING:
      return {
        ...state,
        createLabelReturnLoading: true,
        createLabelReturnSuccess: false,
        createLabelReturnErrorMessage: '',
      };
    case SHIPMENT_PRINT_RETURN_LABEL_SUCCESS:
      return {
        ...state,
        createLabelReturnLoading: false,
        createLabelReturnSuccess: true,
        labelData: action.payload,
      };
    case SHIPMENT_PRINT_RETURN_LABEL_FAILED:
      return {
        ...state,
        createLabelReturnLoading: false,
        createLabelReturnSuccess: false,
        createLabelReturnErrorMessage: action.payload,
      };
    case SHIPMENT_MAIl_SHIPPING_LABEL_LOADING:
      return {
        ...state,
        mailLabelLoading: true,
        mailLabelSuccess: false,
        mailLabelErrorMessage: '',
      };
    case SHIPMENT_MAIl_SHIPPING_LABEL_SUCCESS:
      return {
        ...state,
        mailLabelLoading: false,
        mailLabelSuccess: true,
      };
    case SHIPMENT_MAIl_SHIPPING_LABEL_FAILED:
      return {
        ...state,
        mailLabelLoading: false,
        mailLabelSuccess: false,
        mailLabelErrorMessage: action.payload,
      };
    case SHIPMENT_RESET:
      if (action.payload) {
        return {
          ...initialState,
          labelData: state.labelData,
          qbcustomerData: state.qbcustomerData,
        };
      }
      return initialState;
    case VALIDATE_POSTAL_CODE_LOADING:
      return {
        ...state,
        validatePostalCodeLoading: true,
      };
    case VALIDATE_POSTAL_CODE_SUCCESS:
      return {
        ...state,
        validatePostalCode: true,
        validatePostalCodeLoading: false,
        validatePostalCodeErrorMessage: '',
      };
    case VALIDATE_POSTAL_CODE_FAILED:
      return {
        ...state,
        validatePostalCode: false,
        validatePostalCodeLoading: false,
        validatePostalCodeErrorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default shipmentReducer;
