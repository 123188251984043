import { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = memo(({ style, size, message = '' }) => (
  <div
    className="d-flex flex-column justify-content-center align-items-center"
    style={style}
  >
    <CircularProgress size={size} />
    {message && <h4 className="mt-4">{message}</h4>}
  </div>
));

export default Loader;
