// printerReducer.js
import {
  PRINTER_DEFAULT,
  PRINTER_ERROR,
  PRINTER_STATUS,
  PRINTER_RESET,
  PRINTER_LOADING_TYPE,
} from '../actions/types';

const initialState = {
  defaultPrinter: null,
  printerStatus: null,
  error: null,
  loadingType: '',
};

const printerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRINTER_DEFAULT:
      return {
        ...state,
        defaultPrinter: action.payload,
        loadingType: '',
        error: null,
      };

    case PRINTER_STATUS:
      return {
        ...state,
        loadingType: '',
        printerStatus: action.payload,
        error: null,
      };

    case PRINTER_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingType: '',
        printerStatus: null, // Clear printer status on error
      };
    case PRINTER_RESET:
      return {
        ...state,
        defaultPrinter: null,
        printerStatus: null,
        error: null,
        loadingType: '',
      };
    case PRINTER_LOADING_TYPE:
      return {
        ...state,
        loadingType: action.payload,
      };
    default:
      return state;
  }
};

export default printerReducer;
