import {
  SHIPMENT_LIST_FETCH_LOADING,
  SHIPMENT_LIST_FETCH_SUCCESS,
  SHIPMENT_LIST_FETCH_FAILED,
  SHIPMENT_LIST_DELETE_SUCCESS,
  SHIPMENT_LIST_DELETE_FAILED,
  SHIPMENT_LIST_DELETE_LOADING,
  SHIPMENT_LIST_DELETED_RESET,
} from '../actions/types';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  data: {
    contents: [],
    totalPages: 0,
    currentPage: 0,
  },
  deleted: false,
  deletionLoading: false,
  deletedErrorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHIPMENT_LIST_FETCH_LOADING:
      return {
        loading: true,
      };
    case SHIPMENT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SHIPMENT_LIST_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case SHIPMENT_LIST_DELETE_SUCCESS:
      return {
        ...state,
        deleted: true,
        deletionLoading: false,
        deleted: action.payload,
      };
    case SHIPMENT_LIST_DELETE_FAILED:
      return {
        ...state,
        deletionLoading: false,
        deletedErrorMessage: action.payload,
      };
    case SHIPMENT_LIST_DELETE_LOADING:
      return {
        ...state,
        deletionLoading: true,
      };
    case SHIPMENT_LIST_DELETED_RESET:
      return {
        ...state,
        deleted: false,
        deletionLoading: false,
        deletedErrorMessage: '',
      };
    default:
      return state;
  }
}
