import { combineReducers } from 'redux';
import itemReducer from './itemReducers';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import addressReducer from './AddressReducer';
import invoiceReducer from './InvoiceReducer';
import prescriptionReducer from './prescriptionReducer';
import usersReducer from './usersReducer';
import pickupReducer from './pickupReducer';
import shipmentReducer from './shipmentReducer';
import printerReducer from './printerReducer';
import shipmentListReducer from './shipmentListReducer';

export default combineReducers({
  item: itemReducer,
  error: errorReducer,
  auth: authReducer,
  user: userReducer,
  users: usersReducer,
  address: addressReducer,
  invoice: invoiceReducer,
  prescription: prescriptionReducer,
  pickup: pickupReducer,
  shipment: shipmentReducer,
  printer: printerReducer,
  shipmentList: shipmentListReducer,
});
