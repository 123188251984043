import 'bootstrap/dist/css/bootstrap.min.css';
import './css/global.css';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import React from 'react';
import { Provider } from 'react-redux';
import Routers from './components/Router';
import { Toaster } from 'react-hot-toast';
import store from './store';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5f8bfe',
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Toaster
          position="top-right"
          toastOptions={{
            blank: {
              style: {
                border: '2px solid #ffffff',
                fontFamily: 'Gilroy, sans-serif',
                padding: '2px',
              },
            },
          }}
        />
        <Routers />
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
